import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _isEmpty from 'lodash/isEmpty'
import {
  JobSchedule,
  JobCategory,
  type NavLink,
  JOB_SCHEDULES,
  JOB_CATEGORIES,
  type DropdownOption
} from '@/types'

import DB from '@/data/db.json'

const { jobs } = DB

export const NONE_OPTION: DropdownOption = {
  label: 'None',
  value: 'none'
}

export const JOB_SCHEDULE_OPTIONS = [
  NONE_OPTION,
  ...JOB_SCHEDULES.map(
    (key: string): DropdownOption => ({
      label: JobSchedule[key as keyof typeof JobSchedule],
      value: key
    })
  )
]

export const JOB_CATEGORY_OPTIONS = [
  NONE_OPTION,
  ...JOB_CATEGORIES.map(
    (key: string): DropdownOption => ({
      label: JobCategory[key as keyof typeof JobCategory],
      value: key
    })
  )
]

export const WWR_JOB_CATEGORIES = _uniq(
  _map(jobs, 'category').filter((c) => !_isEmpty(c))
).map(String)

export const NAV_LINKS: NavLink[] = [
  { label: 'Home', href: '/' },

  ...WWR_JOB_CATEGORIES.map(
    (category: string): NavLink => ({
      label: category,
      href: encodeURIComponent(
        `/category/${category.toLowerCase().replace(' ', '-')}`
      )
    })
  )
]
