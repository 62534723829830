'use client'

import cn from 'clsx'
import React from 'react'
import _noop from 'lodash/noop'

import { type Step, type StepsProps } from './types'

const Steps: React.FC<StepsProps> = (props: StepsProps): JSX.Element => {
  const { steps, maxActiveStep, currentStep, setCurrentStep, className } = props
  const maxStep = maxActiveStep ?? currentStep

  return (
    <nav className={className} aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-x-4 md:space-y-0">
        {steps.map(
          ({ title }, i: number): JSX.Element => (
            <li
              key={`step-${title}`}
              className="px-4 py-2 first:pl-0 last:pr-0 md:flex-1"
            >
              <div
                {...(i === currentStep ? { 'aria-current': 'step' } : {})}
                onClick={i <= maxStep ? setCurrentStep.bind(null, i) : _noop}
                className={cn(
                  'md:pt-4 group flex flex-col border-solid border-l-4',
                  'py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0',
                  {
                    'hover:cursor-pointer': i <= maxStep && i !== currentStep,
                    'border-gray-200 dark:border-gray-600': i > maxStep,
                    'border-blue-700 dark:border-blue-500': i === currentStep,
                    'border-blue-300 dark:border-blue-900':
                      i <= maxStep && i !== currentStep,
                    'hover:border-blue-800 dark:hover:border-blue-700':
                      i <= maxStep && i !== currentStep
                  }
                )}
              >
                <div className="flex items-center">
                  <span
                    className={cn('inline text-lg mr-4 items-center', {
                      'font-semibold': i === currentStep,
                      'text-gray-400 dark:text-gray-500': i !== currentStep,
                      'text-black dark:text-white': i === currentStep,
                      'group-hover:text-gray-600 dark:group-hover:text-gray-300':
                        i <= maxStep && i !== currentStep
                    })}
                  >
                    {i + 1}
                  </span>

                  <span
                    className={cn('inline text-lg font-medium', {
                      'font-semibold': i === currentStep,
                      'text-gray-400 dark:text-gray-500': i !== currentStep,
                      'text-black dark:text-white': i === currentStep,
                      'group-hover:text-gray-600 dark:group-hover:text-gray-300':
                        i <= maxStep && i !== currentStep
                    })}
                  >
                    {title}
                  </span>
                </div>
              </div>
            </li>
          )
        )}
      </ol>
    </nav>
  )
}

export default Steps
export { type Step }
