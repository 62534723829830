import PropTypes from 'prop-types'

import { InputType } from './types'

export const propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  large: PropTypes.bool,
  rows: PropTypes.number,
  help: PropTypes.string,
  outline: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errorBorder: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // TODO: Extract as custom prop type
  type: PropTypes.oneOf([
    InputType.Tel,
    InputType.Url,
    InputType.Text,
    InputType.Email,
    InputType.Hidden,
    InputType.Number,
    InputType.Search,
    InputType.Password
  ])
}
