import cn from 'clsx'
import sAgo from 's-ago'
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { type Job } from '@/types'

// TODO: Extract
interface JobDetailsProps {
  job: Job
  className?: string
}

const JobDetails: React.FC<JobDetailsProps> = (
  props: JobDetailsProps
): JSX.Element => {
  const { job, className } = props
  const {
    tags,
    title,
    createdAt,
    companyName,
    description,
    applicationURL,
    companyLogoURL,
    companyWebsiteURL,
    companyDescription
  } = job

  return (
    <div
      className={cn(
        className,
        'bg-gray-100 dark:bg-slate-900 dark:text-white py-16 px-8 lg:px-0'
      )}
    >
      <div className="mx-auto grid max-w-7xl grid-cols-6">
        <div className="col-span-6 lg:col-span-3">
          <h1
            className={cn(
              'text-3xl text-black dark:text-gray-300 font-semibold mb-4'
            )}
          >
            {title}
          </h1>

          <ul className="mb-4">
            {tags.map(
              (tag: string): JSX.Element => (
                <li key={tag} className="mr-4 inline-block last:mr-0">
                  <p
                    className={cn(
                      'px-4 py-1 text-white bg-blue-500 dark:text-gray-200',
                      'dark:bg-blue-900 rounded text-xs'
                    )}
                  >
                    {tag}
                  </p>
                </li>
              )
            )}
          </ul>

          <div
            className={cn(
              'flex mb-8 pb-8 border-solid border-b border-b-gray-300',
              'dark:border-b-gray-700 max-w-2xl items-center'
            )}
          >
            <p className="mr-2 shrink-0 text-gray-600 dark:text-gray-300">
              Posted {sAgo(new Date(createdAt))}
            </p>
          </div>

          <div className="lg:hidden flex justify-center">
            <a
              className="text-white bg-blue-700 px-8 py-2 rounded-lg mb-4 font-semibold shadow-lg"
              target="_blank"
              href={applicationURL}
            >
              Apply
            </a>
          </div>

          <div
            className="leading-8 dark:text-white"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="lg:col-span-1" />

        <div className="col-span-6 lg:col-span-2 mt-8 lg:mt-0">
          <div
            className={cn(
              'bg-white dark:bg-slate-950 rounded-lg shadow-lg p-6'
            )}
          >
            <div className="mb-8 flex items-center">
              <Image
                width={64}
                height={64}
                alt={companyName}
                src={companyLogoURL}
                className="mr-4 rounded-full"
              />

              <div className="flex-column">
                <h2
                  className={cn(
                    'text-xl font-semibold text-black dark:text-gray-200 mb-2'
                  )}
                >
                  {companyName}
                </h2>

                <Link
                  href={companyWebsiteURL}
                  className="text-md text-gray-600 dark:text-gray-300"
                >
                  {companyWebsiteURL}
                </Link>
              </div>
            </div>

            <div
              className="leading-6"
              dangerouslySetInnerHTML={{ __html: companyDescription }}
            />

            <div className="flex justify-center">
              <a
                className="text-white bg-blue-700 px-8 py-2 rounded-lg my-8 font-semibold shadow-lg"
                target="_blank"
                href={applicationURL}
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDetails
