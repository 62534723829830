import PropTypes from 'prop-types'

export const propTypes = {
  dirty: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  undoDisabled: PropTypes.bool,
  changesSaved: PropTypes.bool,
  clearDisabled: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  innerClassName: PropTypes.string,
  onUndo: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  childrenWrapperClassNames: PropTypes.string
}
