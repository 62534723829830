'use client'

import cn from 'clsx'
import _isUndefined from 'lodash/isUndefined'
import React, { type ChangeEvent, useCallback } from 'react'

import { propTypes } from './props'
import { InputType, type InputProps } from './types'

const Input: React.FC<InputProps> = (props: InputProps): JSX.Element => {
  const {
    id,
    rows,
    type,
    icon,
    help,
    value,
    label,
    large,
    outline,
    required,
    children,
    disabled,
    onChange,
    className,
    minLength,
    maxLength,
    errorBorder,
    placeholder,
    autoComplete
  } = props

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (typeof onChange === 'undefined') {
        return
      }

      const { target } = e
      const { value: newValue } = target

      onChange(newValue)
    },
    [onChange]
  )

  const onTextAreaChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      if (typeof onChange === 'undefined') {
        return
      }

      const { target } = e
      const { value: newValue } = target

      onChange(newValue)
    },
    [onChange]
  )

  return (
    <div className={className}>
      {(!_isUndefined(label) || !_isUndefined(help)) && (
        <div className="flex-column mb-2 items-center">
          {!_isUndefined(label) && (
            <label
              htmlFor={id}
              className={cn(
                'flex text-sm font-semibold leading-6 text-gray-900',
                'dark:text-gray-300'
              )}
            >
              {label}

              {!_isUndefined(required) && required && (
                <p className="ml-1 text-xl leading-6 text-red-600">*</p>
              )}
            </label>
          )}

          {!_isUndefined(help) && (
            <p className={cn('text-xs text-gray-400 mb-4')}>{help}</p>
          )}
        </div>
      )}

      {!_isUndefined(children) && children}
      {_isUndefined(children) && (
        <div className="relative">
          {!_isUndefined(icon) && (
            <div
              className={cn(
                'pointer-events-none absolute inset-y-0 left-0 flex',
                'items-center pl-3'
              )}
            >
              {icon}
            </div>
          )}

          {type === InputType.TextArea && (
            <textarea
              id={id}
              name={id}
              rows={rows}
              value={value}
              disabled={disabled}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={onTextAreaChange}
              className={cn(
                'focus:ring-1 focus:ring-inset focus:ring-blue-600',
                'dark:ring-gray-600 dark:placeholder:text-gray-400',
                'block w-full rounded-md border-0 py-1.5 text-gray-900',
                'dark:focus:ring-blue-500 dark:text-gray-200 lg:text-md',
                'ring-1 ring-inset ring-gray-300 placeholder:text-gray-600',
                'sm:leading-6 shadow-sm sm:text-sm bg-white dark:bg-slate-700',
                {
                  'border-solid border-2 border-red-500': errorBorder,
                  'border-solid border-2 border-blue-700':
                    !errorBorder && outline,
                  'px-16 py-2': large && _isUndefined(icon),
                  'py-1.5 px-2': !large && _isUndefined(icon),
                  'pl-24 pr-16 py-8': large && !_isUndefined(icon),
                  'pl-10 pr-2 py-1.5': !large && !_isUndefined(icon)
                }
              )}
            />
          )}

          {type !== InputType.TextArea && (
            <input
              id={id}
              name={id}
              type={type}
              value={value}
              disabled={disabled}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              onChange={onInputChange}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={cn(
                'focus:ring-1 focus:ring-inset focus:ring-blue-600',
                'dark:ring-gray-600 dark:placeholder:text-gray-400',
                'block w-full rounded-md border-0 py-1.5 text-gray-900',
                'dark:focus:ring-blue-500 dark:text-gray-200 lg:text-md',
                'ring-1 ring-inset ring-gray-300 placeholder:text-gray-600',
                'sm:leading-6 shadow-sm sm:text-sm bg-white dark:bg-slate-700',
                {
                  'border-solid border-2 border-red-500': errorBorder,
                  'border-solid border-2 border-blue-500':
                    !errorBorder && outline,
                  'px-6 py-3': large && _isUndefined(icon),
                  'py-1.5 px-2': !large && _isUndefined(icon),
                  'pl-24 pr-8 py-4': large && !_isUndefined(icon),
                  'pl-10 pr-2 py-1.5': !large && !_isUndefined(icon)
                }
              )}
            />
          )}
        </div>
      )}
    </div>
  )
}

Input.propTypes = propTypes

export default Input
export { Input, InputProps, InputType }
