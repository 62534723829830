export interface NavLink {
  readonly href: string
  readonly label: string
}

export enum SortDirection {
  Ascending = 1,
  Descending = 2
}

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

export interface DropdownOption {
  readonly label: string
  readonly value: string
}

export enum Color {
  Red = 'red',
  Sky = 'sky',
  Rose = 'rose',
  Gray = 'gray',
  Zinc = 'zinc',
  Lime = 'lime',
  Teal = 'teal',
  Cyan = 'cyan',
  Blue = 'blue',
  Pink = 'pink',
  White = 'white',
  Black = 'black',
  Slate = 'slate',
  Stone = 'stone',
  Amber = 'amber',
  Green = 'green',
  Orange = 'orange',
  Yellow = 'yellow',
  Indigo = 'indigo',
  Violet = 'violet',
  Purple = 'purple',
  Neutral = 'neutral',
  Emerald = 'emerald',
  Fuchsia = 'fuchsia',
  Transparent = 'transparent'
}
