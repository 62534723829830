import cn from 'clsx'
import React from 'react'
import _isUndefined from 'lodash/isUndefined'

import {
  CenteredContainerContentWidth,
  type CenteredContainerProps
} from './types'

// TODO: Extract max-w-* classes into an enum
const CenteredContainer: React.FC<CenteredContainerProps> = (
  props: CenteredContainerProps
): JSX.Element => {
  const {
    noBG,
    children,
    className,
    noPadding,
    contentWidth,
    contentClassName
  } = props

  return (
    <main
      className={cn(className, {
        'bg-gray-100 dark:bg-slate-800': noBG !== true,
        'py-16 lg:py-50':
          noPadding !== true &&
          !(className ?? '').includes('pt-') &&
          !(className ?? '').includes('pb-')
      })}
    >
      <div
        className={cn(contentClassName, 'mx-auto', {
          'max-w-xs': contentWidth === CenteredContainerContentWidth.xs,
          'max-w-sm': contentWidth === CenteredContainerContentWidth.sm,
          'max-w-md': contentWidth === CenteredContainerContentWidth.md,
          'max-w-lg': contentWidth === CenteredContainerContentWidth.lg,
          'max-w-xl': contentWidth === CenteredContainerContentWidth.xl,
          'max-w-2xl': contentWidth === CenteredContainerContentWidth.xl2,
          'max-w-3xl': contentWidth === CenteredContainerContentWidth.xl3,
          'max-w-4xl': contentWidth === CenteredContainerContentWidth.xl4,
          'max-w-5xl': contentWidth === CenteredContainerContentWidth.xl5,
          'max-w-6xl': contentWidth === CenteredContainerContentWidth.xl6,
          'max-w-7xl':
            _isUndefined(contentWidth) ||
            contentWidth === CenteredContainerContentWidth.xl7
        })}
      >
        {children}
      </div>
    </main>
  )
}

export default CenteredContainer
export { CenteredContainerContentWidth }
