'use client'

import cn from 'clsx'
import _isUndefined from 'lodash/isUndefined'
import React, { Fragment, useRef } from 'react'
import { Dialog as HUDialog, Transition } from '@headlessui/react'

import { propTypes } from './props'
import { type DialogProps } from './types'
import { DEFAULT_CANCEL_LABEL, DEFAULT_CONFIRM_LABEL } from './const'

const Dialog: React.FC<DialogProps> = (props: DialogProps): JSX.Element => {
  const {
    icon,
    title,
    isOpen,
    onClose,
    onCancel,
    onConfirm,
    description,
    cancelLabel = DEFAULT_CANCEL_LABEL,
    confirmLabel = DEFAULT_CONFIRM_LABEL
  } = props

  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HUDialog
        as="div"
        onClose={onClose}
        className="relative z-10"
        initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          leaveTo="opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leave="ease-in duration-200"
          enter="ease-out duration-300"
        >
          <div
            className={cn(
              'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            )}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            className={cn(
              'flex min-h-full items-end justify-center p-4 text-center',
              'sm:items-center sm:p-0'
            )}
          >
            <Transition.Child
              as={Fragment}
              leave="ease-in duration-200"
              enter="ease-out duration-300"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HUDialog.Panel
                className={cn(
                  'relative transform overflow-hidden rounded-lg bg-white',
                  'text-left shadow-xl transition-all sm:my-8 sm:w-full',
                  'sm:max-w-lg'
                )}
              >
                <div className="bg-white px-4 dark:bg-slate-700 sm:p-6 sm:pb-5">
                  <div className="sm:flex sm:items-start">
                    {!_isUndefined(icon) && (
                      <div
                        className={cn(
                          'mx-auto flex h-12 w-12 flex-shrink-0 items-center',
                          'justify-center rounded-full bg-red-100 sm:mx-0',
                          'sm:h-10 sm:w-10'
                        )}
                      >
                        {icon}
                      </div>
                    )}

                    <div
                      className={cn(
                        'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'
                      )}
                    >
                      <HUDialog.Title
                        as="h3"
                        className={cn(
                          'text-base font-semibold leading-6 text-gray-900',
                          'dark:text-white'
                        )}
                      >
                        {title}
                      </HUDialog.Title>

                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {(!_isUndefined(onCancel) || !_isUndefined(onConfirm)) && (
                  <div
                    className={cn(
                      'bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse',
                      'sm:px-6 dark:bg-slate-800'
                    )}
                  >
                    {!_isUndefined(onConfirm) && (
                      <button
                        type="button"
                        onClick={onConfirm}
                        className={cn(
                          'dark:disabled:text-red-300',
                          'dark:hover:border-red-500 disabled:hover:bg-red-100',
                          'rounded-md px-4 py-1 text-sm font-semibold text-white',
                          'dark:disabled:hover:border-red-600 disabled:opacity-50',
                          'dark:disabled:bg-red-700 dark:disabled:hover:bg-red-700',
                          'bg-red-500 shrink-0 border-2 border-solid border-red-600',
                          'dark:disabled:border-red-600 disabled:cursor-not-allowed',
                          'disabled:hover:bg-red-100 dark:bg-red-700 dark:text-white',
                          'disabled:text-red-400 disabled:bg-red-100 hover:bg-red-600',
                          'dark:border-red-600 dark:hover:bg-red-600 hover:border-red-700'
                        )}
                      >
                        {confirmLabel}
                      </button>
                    )}

                    {!_isUndefined(onCancel) && (
                      <button
                        type="button"
                        onClick={onCancel}
                        ref={cancelButtonRef}
                        className={cn(
                          'dark:disabled:text-slate-300',
                          'dark:border-slate-500 dark:hover:bg-slate-500 mr-4',
                          'dark:hover:border-slate-400 disabled:hover:bg-gray-100',
                          'bg-white shrink-0 border-2 border-solid border-gray-300',
                          'dark:disabled:hover:border-slate-600 disabled:opacity-50',
                          'rounded-md px-4 py-1 text-sm font-semibold text-slate-700',
                          'dark:disabled:border-slate-600 disabled:cursor-not-allowed',
                          'dark:disabled:bg-slate-700 dark:disabled:hover:bg-slate-700',
                          'disabled:hover:bg-gray-100 dark:bg-slate-600 dark:text-white',
                          'disabled:text-slate-400 disabled:bg-gray-100 hover:bg-gray-50'
                        )}
                      >
                        {cancelLabel}
                      </button>
                    )}
                  </div>
                )}
              </HUDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HUDialog>
    </Transition.Root>
  )
}

Dialog.propTypes = propTypes

export default Dialog
