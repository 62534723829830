'use client'

import cn from 'clsx'
import React, { Fragment, useMemo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { type DropdownOption } from '@/types'

import { propTypes } from './props'
import { type DropdownProps } from './types'

const Dropdown: React.FC<DropdownProps> = (
  props: DropdownProps
): JSX.Element => {
  const { id, value, options, label, required, onChange, className } = props
  const currentOption = useMemo(
    (): DropdownOption | null =>
      options.find(
        (option: DropdownOption): boolean => option.value === value
      ) ?? null,
    [value, options]
  )

  return (
    <div className={className}>
      <input id={id} name={id} type="hidden" value={value} />

      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            {typeof label !== 'undefined' && (
              <Listbox.Label
                className={cn(
                  'text-sm font-semibold leading-6 text-gray-900 flex',
                  'dark:text-gray-300'
                )}
              >
                {label}
                {typeof required !== 'undefined' && required && (
                  <p className="ml-1 text-xl text-red-600">*</p>
                )}
              </Listbox.Label>
            )}

            <div className="relative mt-2">
              <Listbox.Button
                className={cn(
                  'relative w-full cursor-default rounded-md',
                  'dark:focus:ring-blue-500 dark:text-gray-200',
                  'focus:ring-blue-600 sm:text-sm sm:leading-6',
                  'text-gray-900 dark:text-gray-200 shadow-sm ring-1',
                  'dark:ring-gray-600 dark:placeholder:text-gray-400',
                  'bg-white dark:bg-slate-700 py-1.5 pl-3 pr-10 text-left',
                  'ring-inset ring-gray-300 focus:outline-none focus:ring-2'
                )}
              >
                <span className="block truncate">
                  {currentOption?.label ?? ''}
                </span>

                <span
                  className={cn(
                    'pointer-events-none absolute inset-y-0',
                    'right-0 flex items-center pr-2'
                  )}
                >
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="size-5 text-gray-400"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leaveTo="opacity-0"
                leaveFrom="opacity-100"
                leave="transition ease-in duration-100"
              >
                <Listbox.Options
                  className={cn(
                    'absolute z-10 mt-1 max-h-60 w-full',
                    'text-base shadow-lg ring-1 ring-black',
                    'ring-opacity-5 focus:outline-none sm:text-sm',
                    'overflow-auto rounded-md bg-white dark:bg-blue-950 py-1'
                  )}
                >
                  {options.map(
                    ({
                      label: optionLabel,
                      value: optionValue
                    }): JSX.Element => (
                      <Listbox.Option
                        key={optionValue}
                        value={optionValue}
                        className={({ active }) =>
                          cn('relative cursor-default select-none', {
                            'bg-white dark:bg-blue-950': !active,
                            'text-gray-900 dark:text-gray-400': !active,
                            'text-white bg-blue-600 dark:bg-blue-800': active
                          })
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={cn('block truncate py-2 pl-3 pr-9', {
                                'font-normal': !selected,
                                'text-white font-semibold': selected,
                                'bg-blue-400 dark:bg-blue-900': selected
                              })}
                            >
                              {optionLabel ?? ''}
                            </span>

                            {selected ? (
                              <span
                                className={cn(
                                  'absolute inset-y-0 right-0 flex pr-4',
                                  'items-center',
                                  {
                                    'text-white dark:text-gray-200': active,
                                    'text-blue-600 dark:text-blue-200': !active
                                  }
                                )}
                              >
                                <CheckIcon
                                  aria-hidden="true"
                                  className="size-5"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

Dropdown.propTypes = propTypes

export default Dropdown
export { type DropdownOption }
