import { type Logger } from '@/log'

const { NODE_ENV } = process.env

export const tryCatchLogError = (
  l: Logger,
  errorMessage: string,
  cb: () => void
): void => {
  try {
    cb()
  } catch (err: any) {
    l.error(`${errorMessage}: ${(err as Error)?.message ?? err}`)

    if (NODE_ENV === 'development') {
      l.error(((err as Error)?.stack as string) ?? err)
    }
  }
}
