import { type ReactNode } from 'react'

// TODO: Extract as generic enum
export enum CenteredContainerContentWidth {
  xs = 'max-w-xs',
  sm = 'max-w-sm',
  md = 'max-w-md',
  lg = 'max-w-lg',
  xl = 'max-w-xl',
  xl2 = 'max-w-2xl',
  xl3 = 'max-w-3xl',
  xl4 = 'max-w-4xl',
  xl5 = 'max-w-5xl',
  xl6 = 'max-w-6xl',
  xl7 = 'max-w-7xl'
}

export interface CenteredContainerProps {
  noBG?: boolean
  className?: string
  children: ReactNode
  noPadding?: boolean
  contentClassName?: string
  contentWidth?: CenteredContainerContentWidth
}
