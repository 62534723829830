import PropTypes from 'prop-types'

export const propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxImagesCount: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
}
