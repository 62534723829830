'use client'

import cn from 'clsx'
import _map from 'lodash/map'
import Image from 'next/image'
import { v4 as uuidv4 } from 'uuid'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import { TrashIcon } from '@heroicons/react/24/solid'

import React, { useMemo, useState, useCallback } from 'react'
import { UploadButton } from '@bytescale/upload-widget-react'
import { type UploadWidgetResult } from '@bytescale/upload-widget'

import Input, { InputType } from '@/components/input'

import { propTypes } from './props'
import { type ImageInputProps } from './types'

// TODO: Fix; next.js updated & process.env is unavailable
// const { NEXT_PUBLIC_BYTESCALE_API_KEY = '' } = process.env
const NEXT_PUBLIC_BYTESCALE_API_KEY = 'public_kW15bP8EKVFJ36wPjye1v2tPY9pT'

const ImageInput: React.FC<ImageInputProps> = (
  props: ImageInputProps
): JSX.Element => {
  const {
    id,
    help,
    label,
    value,
    children,
    required,
    onChange,
    className,
    maxImagesCount
  } = props

  if (maxImagesCount <= 0) {
    throw new Error(`Max images count must be > 0: ${maxImagesCount}`)
  }

  const valueArray = useMemo(
    () =>
      (Array.isArray(value) ? value : [value]).filter(
        (v: string): boolean => !_isEmpty(v)
      ),
    [value]
  )

  const [error, setError] = useState<string | null>(null)
  const uploadButtonOptions = useMemo(
    () => ({
      apiKey: NEXT_PUBLIC_BYTESCALE_API_KEY,
      multi: maxImagesCount > 1,
      maxFileCount: maxImagesCount - valueArray.length,
      mimeTypes: ['image/jpeg', 'image/png', 'image/webp']
    }),
    [maxImagesCount, valueArray]
  )

  const onImageUploadComplete = useCallback(
    (results: UploadWidgetResult[]): void => {
      if (results.length > maxImagesCount) {
        setError(`Expected only ${maxImagesCount} files`)
      } else if (results.length === 0) {
        setError('Expected at least one file')
      } else {
        setError(null)
        onChange(_map(results, 'fileUrl'))
      }
    },
    [onChange, setError, maxImagesCount]
  )

  const onRemoveImage = useCallback(
    (i: number): void => {
      onChange(valueArray.filter((_, j) => j !== i))
    },
    [valueArray, onChange]
  )

  return (
    <Input label={label} help={help} required={required} className={className}>
      {valueArray.map(
        (imageUrl: string): JSX.Element => (
          <input
            id={id}
            value={imageUrl}
            type={InputType.Url}
            key={`input-${uuidv4()}`}
            name={maxImagesCount === 1 ? id : `${id}[]`}
            readOnly
            hidden
          />
        )
      )}

      {valueArray.length < maxImagesCount && (
        <UploadButton
          options={uploadButtonOptions}
          onComplete={onImageUploadComplete}
        >
          {({ onClick }): JSX.Element => (
            <button
              onClick={onClick}
              className={cn(
                'px-4 py-2 bg-blue-800 text-sm text-white rounded',
                'hover:bg-blue-900 dark:bg-blue-700 hover:dark:bg-blue-900'
              )}
            >
              Upload
            </button>
          )}
        </UploadButton>
      )}

      {_isUndefined(children) && valueArray.length > 0 && (
        <ul className="flex">
          {valueArray.map(
            (imageUrl: string, i: number): JSX.Element => (
              <button
                key={`image-${uuidv4()}`}
                className="relative size-10"
                onClick={onRemoveImage.bind(null, i)}
              >
                <Image
                  quality={90}
                  layout="fill"
                  src={imageUrl}
                  objectFit="contain"
                  className="rounded-full"
                  alt={`Uploaded Image ${i + 1}`}
                  fill
                />

                <div
                  className={cn(
                    'absolute p-1 top-[-8px] right-[-8px] rounded-full',
                    'bg-red-500 hover:bg-red-700 hover:cursor-pointer'
                  )}
                >
                  <TrashIcon className="size-4 rounded-full text-white" />
                </div>
              </button>
            )
          )}
        </ul>
      )}

      {!_isUndefined(children) && children}
      {error !== null && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </Input>
  )
}

ImageInput.propTypes = propTypes

export default ImageInput
