import { type ReactNode } from 'react'

export enum InputType {
  Url = 'url',
  Tel = 'tel',
  Text = 'text',
  Email = 'email',
  Number = 'number',
  Search = 'search',
  Hidden = 'hidden',
  TextArea = 'textarea',
  Password = 'password'
}

export interface InputProps {
  id?: string
  help?: string
  rows?: number
  label?: string
  large?: boolean
  type?: InputType
  icon?: ReactNode
  outline?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  required?: boolean
  className?: string
  placeholder?: string
  children?: ReactNode
  errorBorder?: boolean
  autoComplete?: string
  value?: string | number
  onChange?: (value: any) => void
}
