export enum JobSchedule {
  Contract = 'Contract',
  FullTime = 'Full Time',
  PartTime = 'Part Time',
  Temporary = 'Temporary',
  Freelance = 'Freelance',
  Internship = 'Internship'
}

export enum JobCategory {
  All = 'All',
  Design = 'Design',
  DevOps = 'DevOps',
  CustomerSupport = 'Customer Support',
  MobileProgramming = 'Mobile Programming',
  ProductManagement = 'Product Management',
  BackendProgramming = 'Backend Programming',
  FrontendProgramming = 'Frontend Programming',
  FullStackProgramming = 'Full Stack Programming'
}

export const JOB_SCHEDULES = Object.keys(JobSchedule)
export const JOB_CATEGORIES = Object.keys(JobCategory)

// TODO: Remove in leu of mongoose model
export interface Job {
  readonly id: string
  readonly title: string
  readonly tags: string[]
  readonly address: string
  readonly createdAt: number
  readonly description: string
  readonly companyName: string
  readonly category: JobCategory
  readonly schedule: JobSchedule
  readonly companyLogoURL: string
  readonly applicationURL: string
  readonly salaryMin: number | null
  readonly salaryMax: number | null
  readonly companyWebsiteURL: string
  readonly companyDescription: string
  readonly salaryCurrency: string | null
}

export interface WeWorkRemotelyJob {
  tags: string[]
  jobTitle: string
  postedDate: number
  companyName: string
  applications: number
  category?: JobCategory
  companyLogoURL: string
  jobDescription: string
  applicationURL: string
  companyLocation: string
  companyDescription: string
}
