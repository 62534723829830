interface Storage {
  length: number
  clear: () => void
  removeItem: (key: string) => void
  key: (index: number) => string | null
  getItem: (key: string) => string | null
  setItem: (key: string, value: string) => void
}

export enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage'
}

const TEST_STRING = '__storage_test__'

/**
 * Taken from [MDN](https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API)
 *
 * Detects whether either StorageType.LocalStorage or
 * StorageType.SessionStorage is both supported and available.
 *
 * @param {StorageType} storageType - The type of storage to check
 * @returns {boolean} - Whether the storage is available
 */
export const isStorageAvailable = (storageType: StorageType): boolean => {
  let storage = null

  try {
    storage = window[storageType as keyof typeof window] as Storage
    storage.setItem(TEST_STRING, TEST_STRING)
    storage.removeItem(TEST_STRING)
  } catch (err: any) {
    return false
  }

  return true
}

export default isStorageAvailable
