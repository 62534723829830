'use client'

import cn from 'clsx'
import _noop from 'lodash/noop'
import React, { useMemo } from 'react'
import _isUndefined from 'lodash/isUndefined'
import { ExclamationTriangleIcon, CheckIcon } from '@heroicons/react/24/outline'

import { propTypes } from './props'
import { type CardFormProps } from './types'

const CardForm: React.FC<CardFormProps> = (
  props: CardFormProps
): JSX.Element => {
  const {
    title,
    dirty,
    onSave,
    onUndo,
    onClear,
    children,
    className,
    description,
    saveDisabled,
    changesSaved,
    undoDisabled,
    errorMessage,
    clearDisabled,
    innerClassName,
    childrenWrapperClassNames
  } = props

  const buttonGridColumns = useMemo(
    () =>
      [
        !_isUndefined(onSave),
        !_isUndefined(onUndo),
        !_isUndefined(onClear)
      ].filter(Boolean).length,
    [onSave, onUndo, onClear]
  )

  const hasSidebar =
    !_isUndefined(title) ||
    !_isUndefined(description) ||
    !_isUndefined(errorMessage)

  return (
    <div className={className ?? 'space-y-10 divide-y divide-gray-900/10'}>
      <div
        className={
          innerClassName ?? 'grid grid-cols-1 gap-8 pt-10 md:grid-cols-8'
        }
      >
        {hasSidebar && (
          <div className="px-4 sm:px-0 md:col-span-2">
            {!_isUndefined(title) && (
              <h2
                className={cn(
                  'text-base font-semibold leading-7 text-gray-900',
                  'dark:text-gray-300'
                )}
              >
                {title}
              </h2>
            )}

            {!_isUndefined(description) && (
              <p
                className={cn(
                  'mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400'
                )}
              >
                {description}
              </p>
            )}

            {!_isUndefined(errorMessage) && (
              <p className="text-md mt-4 max-w-xl font-semibold text-red-500">
                {errorMessage}
              </p>
            )}
          </div>
        )}

        <form
          className={cn(
            'bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl',
            'md:col-span-6 dark:bg-slate-900 dark:ring-gray-300/5',
            {
              'md:col-span-6': hasSidebar,
              'md:col-span-8': !hasSidebar
            }
          )}
        >
          <div
            className={cn(
              'p-8 border-solid border-b border-b-gray-300',
              'dark:border-b-gray-800'
            )}
          >
            <div
              className={
                childrenWrapperClassNames ??
                cn('grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6')
              }
            >
              {children}
            </div>
          </div>

          {(!_isUndefined(onSave) || !_isUndefined(onClear)) && (
            <div
              className={cn(
                'flex items-center gap-x-6 border-t border-gray-900/10 p-4',
                'sm:px-8 text-sm bg-gray-100 sm:rounded-b-xl dark:bg-slate-950'
              )}
            >
              {!dirty && !changesSaved && <p className="w-full"></p>}
              {changesSaved && (
                <p
                  className={cn(
                    'text-sm text-gray-600 dark:text-gray-300 flex',
                    'items-center w-full'
                  )}
                >
                  <CheckIcon className="mr-2 size-4 text-green-500" />
                  Saved
                </p>
              )}

              {dirty && !changesSaved && (
                <p
                  className={cn(
                    'text-sm text-gray-600 dark:text-gray-300 flex',
                    'items-center w-full'
                  )}
                >
                  <ExclamationTriangleIcon className="mr-2 size-4 text-orange-500" />
                  Unsaved Changes
                </p>
              )}

              <div className="flex shrink-0 items-center justify-end">
                <div
                  className={cn('grid grid-rows-1 gap-x-4', {
                    'grid-cols-1': buttonGridColumns === 1,
                    'grid-cols-2': buttonGridColumns === 2,
                    'grid-cols-3': buttonGridColumns === 3
                  })}
                >
                  {!_isUndefined(onClear) && (
                    <button
                      type="button"
                      disabled={clearDisabled}
                      onClick={clearDisabled ? _noop : onClear}
                      className={cn(
                        'dark:disabled:border-gray-600',
                        'dark:focus-visible:outline-bg-slate-900',
                        'hover:bg-gray-100 dark:hover:bg-slate-600',
                        'disabled:bg-white dark:disabled:bg-slate-700',
                        'hover:text-black dark:hover:border-slate-500',
                        'disabled:hover:bg-white dark:hover:text-white',
                        'border-solid border-gray-300 disabled:opacity-50',
                        'bg-white dark:bg-slate-700 dark:border-slate-600',
                        'focus-visible:outline-bg-slate-600 text-slate-700',
                        'disabled:text-gray-900 dark:disabled:text-gray-300',
                        'text-sm font-semibold px-4 py-2 rounded-md border-2',
                        'disabled:cursor-not-allowed disabled:border-gray-200',
                        'dark:disabled:hover:bg-slate-700 dark:text-slate-200'
                      )}
                    >
                      Clear
                    </button>
                  )}

                  {!_isUndefined(onUndo) && (
                    <button
                      type="button"
                      disabled={undoDisabled}
                      onClick={undoDisabled ? _noop : onUndo}
                      className={cn(
                        'dark:disabled:border-gray-600',
                        'dark:focus-visible:outline-bg-slate-900',
                        'hover:bg-gray-100 dark:hover:bg-slate-600',
                        'disabled:bg-white dark:disabled:bg-slate-700',
                        'hover:text-black dark:hover:border-slate-500',
                        'disabled:hover:bg-white dark:hover:text-white',
                        'border-solid border-gray-300 disabled:opacity-50',
                        'bg-white dark:bg-slate-700 dark:border-slate-600',
                        'focus-visible:outline-bg-slate-600 text-slate-700',
                        'disabled:text-gray-900 dark:disabled:text-gray-300',
                        'text-sm font-semibold px-4 py-2 rounded-md border-2',
                        'disabled:cursor-not-allowed disabled:border-gray-200',
                        'dark:disabled:hover:bg-slate-700 dark:text-slate-200'
                      )}
                    >
                      Undo
                    </button>
                  )}

                  {!_isUndefined(onSave) && (
                    <button
                      type="button"
                      disabled={saveDisabled === true}
                      onClick={saveDisabled ? _noop : onSave}
                      className={cn(
                        'dark:hover:bg-green-700',
                        'dark:focus-visible:outline-bg-slate-900',
                        'dark:text-slate-100 disabled:border-gray-200',
                        'disabled:bg-white dark:disabled:bg-slate-700',
                        'disabled:hover:bg-white dark:hover:text-white',
                        'disabled:cursor-not-allowed disabled:opacity-50',
                        'dark:border-green-700 dark:hover:border-green-600',
                        'disabled:text-gray-900 dark:disabled:text-gray-300',
                        'text-sm font-semibold px-4 py-2 rounded-md border-2',
                        'dark:disabled:hover:bg-slate-700 hover:bg-green-700',
                        'focus-visible:outline-bg-slate-600 dark:bg-green-800',
                        'hover:border-green-900 dark:disabled:border-gray-600',
                        'border-solid border-green-700 bg-green-600 text-white'
                      )}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

CardForm.propTypes = propTypes

export default CardForm
